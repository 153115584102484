/* Sidebar.css */
.sidebar-link {
  display: flex;
  align-items: center;
  color: #b3b3b3;
  padding: 8px 16px;
  text-decoration: none;
  gap: 8px;
  font-size: .875rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.sidebar-link:hover {
  background-color: #191A23; /* Adjust the hover background color as needed */
}

.email-display {
  max-width: calc(100% - 20px); /* accounting for padding or icons */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px 10px; /* Adjust padding as needed */
  display: block; /* Ensure it takes full width for background styling */
  text-align: center; /* Center the text horizontally */
  margin: auto; /* Centering block elements horizontally if needed */
}

/* Sidebar link styles remain unchanged */

@media (max-width: 1600px) {
  /* Mobile sidebar functionality */
  .sidebar {
    position: fixed; /* Sidebar now fixed on mobile */
    top: 0;
    left: 0;
    width: 250px; /* Set width of sidebar */
    height: 100%;
    background-color: #191A23;
    transform: translateX(-100%); /* Hidden by default */
    transition: transform 0.3s ease; /* Smooth transition */
    z-index: 1100; /* Make sure sidebar appears above content */
  }

  .sidebar.open {
    transform: translateX(0); /* Sidebar slides in when open */
  }

  /* Mobile-only arrow button */
  .mobile-sidebar-toggle {
    position: fixed;
    top: 50%; /* Position arrow at the vertical center */
    left: 10px;
    transform: translateY(-50%); /* Adjust for exact centering */
    z-index: 1200;
    background-color: #ccc;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Arrow open/close */
  .arrow {
    font-size: 20px;
    color: black;
	transform: rotate(180deg);
  }

  .arrow.open {
      font-size: 20px;
      color: black;
	  transform: rotate(0deg);
  }

  /* Ensure the main content takes up the full width when the sidebar is hidden */
  .main-content {
    width: 100%;
    padding-left: 0; /* Reset padding when sidebar is hidden */
    transition: padding-left 0.3s ease;
  }

  /* Adjust content when the sidebar is open */
  .main-content.sidebar-open {
    padding-left: 250px; /* Sidebar pushes content when open */
  }
}

/* Hide the mobile arrow on desktop */
@media (min-width: 1600px) {
  .mobile-sidebar-toggle {
    display: none;
  }
}


