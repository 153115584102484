/* Container for centering content */
.container {
  max-width: 1400px; /* Adjust the max-width to make the margins narrower */
  margin: 0 auto; /* Center the container */
  padding: 0 20px; /* Add some padding on the sides */
}

/* Section margins */
section {
  margin-bottom: 4rem; /* Default space between sections */
}

/* Hero section */
.hero-section {
  background-image: url('./images/hero-background.jpg'); /* Make sure this path is correct */
  background-size: cover;
  background-position: center;
  position: relative;
  height: 50vh; /* Ensure the section takes the full viewport height */
  width: 100%; /* Make the section full width */
  margin: 0 auto;
  margin-bottom: 6rem; /* Add more space below the hero section */
}

.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* This adds the dark tint */
}

.hero-section h1, .hero-section .button-link {
  position: relative;
  z-index: 1;
}

/* Why contentEnhance section */
.why-section {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-bottom: 3rem;
  padding: 2rem 0;
}

.why-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  margin-bottom: 3rem;
}

.why-block.flex-row-reverse {
  flex-direction: row-reverse;
}

.why-text {
  flex: 1;
  font-size: 1rem;
  padding: 0 1rem;
  flex-basis: 50%; /* Set the text container to one-third */
}

.why-heading {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.why-image {
  flex: 1;
  text-align: center;

  flex-basis: 50%; /* Set the image container to two-thirds */
}

.why-image img {
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
}

@media (max-width: 768px) {
  .why-block {
    flex-direction: column;
    text-align: center;
  }
  
  .why-block.flex-row-reverse {
    flex-direction: column;
  }
}

.button-link {
  background-color: #3b82f6; /* Tailwind's blue-500 */
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  z-index: 2; /* Ensure it is above other elements */
}

.button-link:hover {
  background-color: #1c3d7c; /* Tailwind's blue-700 */
}

/* Features section */
.features-section {
  display: grid;
  grid-template-columns: 1fr; /* Single column for small screens */
  gap: 2rem;
  padding: 4rem 1rem;
  background-color: white;
  color: black;
  text-align: center;
  margin-bottom: 2rem; /* Reduce space below the features section */
}

@media (min-width: 768px) {
  .features-section {
    grid-template-columns: 1fr 1fr; /* Two columns for medium screens and above */
  }
}

.features-section .feature {
  text-align: center;
  max-width: 30rem; /* Ensure feature blocks don't get too wide */
  margin: 0 auto; /* Center feature blocks */
  padding: 0 1rem;
}

.pricing-plans {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px; /* Adds space between the blocks */
  width: 100%; /* Ensures the container takes full width */
  max-width: 1400px; /* Limits max width to match the container */
  margin: 0 auto; /* Center the container */
}

.pricing-plan {
  padding: 2rem;
  margin: 1rem;
  border-radius: 0.5rem;
  text-align: center;
  flex: 1 1 300px; /* Allows flex items to grow and shrink but not smaller than 300px */
  min-width: 250px; /* Ensures a minimum width for the plans */
  border: 2px solid #EEEEEE; /* Grey border color */
}

.pricing-icon {
  font-size: 70px; /* Adjust the size of the icons */
  margin-bottom: 10px; /* Space between the icon and the title */
}

.icon-red {
  color: #f44336; /* Red color */
}

.icon-blue {
  color: #2196f3; /* Blue color */
}

.icon-purple {
  color: #9c27b0; /* Purple color */
}

.icon-orange {
  color: #ff9800; /* Orange color */
}

@media (max-width: 1024px) {
  .pricing-plan {
    max-width: calc(33.33% - 40px); /* Three items per row, accounting for gap */
  }
}

@media (max-width: 768px) {
  .pricing-plan {
    max-width: 100%; /* Each plan takes full width on small screens */
  }
}

/* Add margin to footer to align with container */

footer {
  width: 100%;
  margin: 0;
  padding: 20px;
}

.dropdown-container {
  max-width: 300px; /* Adjust the max-width as needed */
}

.sidebar-link {
  display: flex;
  align-items: center;
  color: #b3b3b3;
  padding: 8px 16px;
  text-decoration: none;
  gap: 8px;
  font-size: 0.875rem;
}

.sidebar-link:hover {
  color: #ffffff;
}

.icon-style {
  color: #b3b3b3;
  font-size: 1.5rem;
}

.arrow-style {
  color: #b3b3b3;
  font-size: 0.875rem;
  margin-left: auto;
}

/* Media query for thinner margins on mobile */
@media (max-width: 768px) {
  .container {
    padding: 0 2px; /* Reduce padding for smaller screens */
  }

  footer {
    padding: 0 2px; /* Reduce padding for footer on smaller screens */
  }
}

/* Add any necessary styling for the top bar links */
.top-bar-link {
  color: black;
  margin-left: 2rem;
  text-decoration: none;
}

.top-bar-link:hover {
  color: #d1d5db; /* Lighter gray on hover */
}

/* Mobile menu styling */
.md-hidden {
  display: none;
}

@media (max-width: 768px) {
  .md-hidden {
    display: block;
  }

  .md-flex {
    display: none;
  }
}

/* Footer Container */
.footer-container {
  width: 100%;
  margin: 0;
  padding: 0 20px;
}

/* Footer Heading */
.footer-h3 {
  font-size: 1.125rem; /* Tailwind's text-lg */
  font-weight: 600; /* Tailwind's font-semibold */
  margin-bottom: 0.5rem; /* Tailwind's mb-2 */
  color: black; /* Ensure black text color */
  text-align: left; /* Align text to the left */
}

/* Footer List */
.footer-ul {
  list-style-type: none;
  padding: 0;
  text-align: left; /* Align text to the left */
}

/* Footer List Item */
.footer-li {
  margin-bottom: 0.5rem; /* Tailwind's mb-2 */
}

/* Footer Link */
.footer-a {
  text-decoration: none;
  color: black; /* Ensure black text color */
}

.footer-a:hover {
  text-decoration: underline;
}

/* Flex Container */
.footer-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* or space-evenly */
}

/* Footer Column */
.footer-column {
    flex: 1 1 0%;
    margin: 0 1rem; /* optional horizontal gap */
  text-align: left; /* Align text to the left */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
}

.footer-column:last-child {
  margin-right: 0;
}
.footer-column .content-wrapper {
  width: 100%;
  max-width: 200px; /* Adjust based on your design */
  text-align: left;
}

.breakout-block {
  background-color: #f0f4f8; /* Light gray background */
  padding: 20px;
  border-radius: 8px;
  gap: 1rem;
  margin-bottom: 8rem;
}
/* Responsive Styles */
@media (max-width: 768px) {
  .footer-column {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
    align-items: center; /* Center items horizontally on small screens */
  }
}

/* General layout improvements */
.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.hero-section-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 6rem 1rem;
  gap: 2rem;
}

@media (min-width: 1024px) {
  .hero-section-mobile {
    flex-direction: row;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }

  .text-content {
    max-width: 600px;
    text-align: left;
  }

  .chart-image {
    flex: 1;
    max-width: 700px; /* Increase the max-width for larger screens */
    justify-content: flex-end;
  }
}


/* Improved layout for chart and text on larger screens */
.chart-image {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (min-width: 1024px) {
  .chart-image {
    justify-content: flex-end;
    max-width: 700px; /* Increase chart width on larger screens */
  }
}
