/* First, import the Google Font */
@import url('https://fonts.googleapis.com/css2?family=Baloo+Tamma+2:wght@400;700&display=swap');
/* Add this at the top of your index.css file, before the Tailwind directives */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


/* Then, add your Tailwind directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-body;
}

/* Global styles */
*, *::before, *::after {
  box-sizing: border-box;
}

body {
  @apply font-body text-base text-darkBlue bg-white;
}

a {
  @apply text-lightBlue no-underline;
}
a:hover {
  @apply underline;
}

/* Component classes */
.btn-primary {
  @apply bg-lightBlue text-white font-semibold py-2 px-4 rounded;
}
.btn-primary:hover {
  @apply bg-lightBlue-dark;
}
.btn-primary:focus {
  @apply ring-2 ring-lightBlue-dark ring-opacity-50;
}

.no-border-text-center {
  @apply border-b-0 text-center;
}

/* Responsive design */
@screen md {
  .btn-primary {
    @apply py-3 px-6;
  }
}
