/* General Styles */
.container {
  max-width: 1400px;
  margin: 0 auto;
}

section {
  margin-bottom: 1rem;
  line-height: 1.6;
}

/* Breakout Block */
.breakout-block {
  background-color: transparent !important;
  border-radius: 8px;
  gap: 1rem;
  margin-bottom: 0.5rem; /* Reduced margin-bottom for tighter spacing */
}

/* Why Section */
.why-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0;
}

.why-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0;
}

.why-text {
  flex: 1;
  font-size: 1.1rem;
  flex-basis: 50%;
}

/* Consistent Font Sizes */
h2 {
  font-size: 2rem; /* Ensuring consistent font size for headings */
}

p {
  font-size: 1rem; /* Consistent font size for paragraphs */
}

/* Performance Section */
.performance-section {
  margin-left: 12px;
  margin-top: -9.5rem;
}

.performance-text h2 {
  font-size: 2.5rem; /* Heading font size */
  margin-top: 4rem; /* Increased margin-top for separation from previous section */
  margin-bottom: 1.5rem;
}

.performance-text p,
.performance-text ul {
  margin-bottom: 1.5rem;
}

/* List Styles */
.list-disc {
  list-style-type: disc;
  margin-left: 2rem;
  line-height: 1.7;
}

/* Button Link */
.button-link {
  background-color: #3b82f6;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.button-link:hover {
  background-color: #1c3d7c;
}

/* Pricing Section */
.pricing-section {
  margin-top: 3rem; /* Reduced margin-top for tighter layout */
}

/* Footer */
footer {
  padding: 20px 0;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .container {
    padding: 0;
  }

  .why-block {
    flex-direction: column;
    text-align: center;
  }

  footer {
    padding: 0;
  }

  .performance-text h2 {
    font-size: 2rem;
  }
}

.cta-container {
  background-color: #f9fafb;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  gap: 1.5rem;
}

.chart-container {
  text-align: center;
}

.cta-text-container p {
  color: #000;
}

.language-python .keyword { color: #ff79c6; }  /* pink */
.language-python .function { color: #50fa7b; } /* green */
.language-python .string { color: #f1fa8c; }   /* yellow */
.language-python .comment { color: #6272a4; }  /* blue-gray */
.language-python .operator { color: #ff79c6; } /* pink */
.language-python .number { color: #bd93f9; }   /* purple */
.language-python .punctuation { color: #f8f8f2; } /* light gray */

@media (max-width: 768px) {
  .cta-container {
    flex-direction: column;
  }

  .cta-text-container {
    text-align: center;
    padding-left: 0;
  }
}

